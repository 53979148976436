@keyframes blink {
  0% {
    color: lightblue;
  }
  50% {
    color: blue;
  }
  100% {
    color: darkblue;
  }
}

.blink_container {
  animation: blink 0.5s linear infinite;
  cursor: pointer;
  font-size: 1.4rem;
  /* white-space: nowrap; */

  /* display: inline-block; */
}
