@media screen and (max-width: 160000px) {
    .contan{
        margin-right: 20%;
    }
}
@media screen and (max-width: 600px) {
    .nav_container, .nav_logo{
        font-size: 3.5vw;
        margin-left: 1vw;
    }
    .contan{
        margin-right: 35%;
    }
    
}



.projects{
    font-weight: bold;
}

.shadow {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

/* .nav_container{
    font-size: 1.2vw;
} */
.navFlex{
    display: flex;
    gap: 10%;

    /* position: relative;
    right: 10rem; */
}
.nav{
    margin: 3%;
}
.nav_bootstrap{
    width: 100%;
}

.image_logo{
    height: 3vw;
    width: 6vw;
}

.nav_logo{
    position: relative;
    cursor: pointer;
}

.blink{
    position: fixed;
    right: 5%;
}
/* .contan{
    margin-right: 25%;
} */