@media screen and (max-width: 1000000px) {
    .Cont{
        width: 15vw;
        height: 55vh;
        font-size: 1vw;
    }
}
@media screen and (max-width: 650px) {
    .Cont{
        width: 23vw;
        height: 45vh;
        font-size: 2vw;
    }
    
}
@media screen and (max-width: 500px) {
    .Cont{
        width: 33vw;
        height: 50vh;
        font-size: 3vw;
    }
    
}
@media screen and (max-width: 400px) {
    .Cont{
        width: 45vw;
        height: 47vh;
        font-size: 3.2vw;
    }
    
}
@media screen and (max-width: 200px) {
    .Cont{
        width: 48vw;
        height: 40vh;
        font-size: 4vw;
    }
    
}



.Pic{
    z-index: 1;
    width: 100%;
    height: 60%;
}
.Pic:hover{
    filter: brightness(30%);
}
.type{
    position: relative;
    top:-80%;
    color: white;
    font-size: 6vh;
    z-index: 2;
}


.data{
    /* text-align: right; */
    position: relative;
    top: -50%;
    /* margin: 5px; */
}
.name_header{
    margin-bottom: 4%;
}
.pic_open{
    filter: brightness(40%);
    cursor: pointer;
    height: 100%;
}



.img_modal{
    position:relative;
    top:0px;
    width:100%;
}