

.ourproj_contain{
    width: 20rem;
    height: 10rem;
    display: flex;
    margin-bottom: 5%;
}

.img_ourproj{
    height: 100%;
    width: 50%;
}

.centerY{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .7rem;
}
.text_ourproj{
    font-size: .7rem;
}
.logo{
    height:4rem;
    width: 4rem;
}
@media screen and (min-width:830px){
    .ourproj_contain{
        width: 60%;
        height: 20rem;
    }
    .img_ourproj{
        height: 100%;
        width: 50%;
    }
    .centerY{
        font-size: 1.5rem;
        width: 50%;
    }
    .logo{
        height:6rem;
        width: 6rem;
    }
    .text_ourproj{
        font-size: 1rem;
    }
}