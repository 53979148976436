@media screen and (max-width: 1500000000px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 1vw;
    }
}

@media screen and (max-width: 1100px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 1.2vw;
    }
}
@media screen and (max-width: 900px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 1.3vw;
    }
}
@media screen and (max-width: 750px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 1.4vw;
    }
}
@media screen and (max-width: 600px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 1.5vw;
    }
}
@media screen and (max-width: 400px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 1.7vw;
    }
}
@media screen and (max-width: 300px) {
    .navigateFl, .body_foot, .logo_img, .po {
        font-size: 2.5vw;
    }
}

.contain_navbar{
    background-color:#d3d3d3ac;
    margin-top: 0;
    width:100%;
    margin-bottom: 0vh;
    float:left;

}

/* body{
    z-index: 0;

} */

.navigateFl, .body_foot, .logo_img
{
    margin-top:3%;
    width: 33%;
    height: 100%;
}

.navigateFl{
    float: right;
    clear:left;
}


.po{
    cursor: pointer;
    width: 22%;
    position: relative;
    margin: 1vh;
    right: 30%;
}
.po2{
    width: 22%;
    position: relative;
    margin: 1vh;
    right: 30%;
}

.po:hover{
    font-weight: bold;
    /* color: blue; */
    position: relative;
    top: -7px;
}

.contain_menu{
    width: 100%;
    
}

.imageLogo{
    height: 10vw;
    width: 20vw;
}