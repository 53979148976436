@media screen and (max-width: 150000000px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 3vw;
    }
    /* .about_container{
        margin-bottom: 24vw;
        
    } */
    
}
@media screen and (max-width: 2100px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2vw;
    }
    /* .about_container{
        margin-bottom: 21.7vw;
        
    } */
    
}
@media screen and (max-width: 2000px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2vw;
    }
    /* .about_container{
        margin-bottom: 21vw;
        
    } */
    
}
@media screen and (max-width: 1800px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2vw;
    }
    /* .about_container{
        margin-bottom: 19.7vw;
        
    } */
    
}
@media screen and (max-width: 1700px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2vw;
    }
    /* .about_container{
        margin-bottom: 19vw;
        
    } */
    
}
@media screen and (max-width: 1600px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2vw;
    }
    /* .about_container{
        margin-bottom: 18.2vw;
        
    } */
    
}
@media screen and (max-width: 1500px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 3vw;
    }
    /* .about_container{
        margin-bottom: 17.5vw;
        
    } */
    
}
@media screen and (max-width: 1400px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2.5vw;
    }
    /* .about_container{
        margin-bottom: 17vw;
        
    } */
    
}
@media screen and (max-width: 1350px) {
    .owners_txt, .f2{
        font-size: 1.1vw;
    }
    .owners h1{
        font-size: 1.5vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2vw;
    }
    /* .about_container{
        margin-bottom: 17vw;
        
    } */
    
}
@media screen and (max-width: 1300px) {
    .owners_txt, .f2{
        font-size: 0.9vw;
    }
    .owners h1{
        font-size: 1.8vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2.5vw;
    }
    /* .about_container{
        margin-bottom: 16vw;
        
    } */
    
}

@media screen and (max-width: 1200px) {
    .owners_txt, .f2{
        font-size: 0.9vw;
    }
    .owners h1{
        font-size: 1.8vw;
    }
    .company_name{
        margin-top:80px;
    }
    .name_company{
        font-size: 2.5vw;
    }
    /* .about_container{
        margin-bottom: 12vw;
        
    } */
    
}
@media screen and (max-width: 1100px) {
    .owners_txt, .f2{
        font-size: 1.2vw;
    }
    .owners h1{
        font-size: 2vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 3vw;
    }
    /* .about_container{
        margin-bottom: 30vw;
        
    } */
    
}
@media screen and (max-width: 900px) {
    .owners_txt, .f2{
        font-size: 1.3vw;
    }
    .owners h1{
        font-size: 3vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 3vw;
    }
    /* .about_container{
        margin-bottom: 95vw;
        
    } */
    
}
@media screen and (max-width: 800px) {
    .owners_txt, .f2{
        font-size: 1.5vw;
    }
    .owners h1{
        font-size: 3vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 5vw;
    }
    /* .about_container{
        margin-bottom: 80vw;
        
    } */
    
}
@media screen and (max-width: 700px) {
    .owners_txt, .f2{
        font-size: 1.5vw;
    }
    .owners h1{
        font-size: 3vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 5vw;
    }
    /* .about_container{
        margin-bottom: 90vw;
        
    } */
    
}
@media screen and (max-width: 600px) {
    .owners_txt, .f2{
        font-size: 1.5vw;
    }
    .owners h1{
        font-size: 3vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 5vw;
    }
    /* .about_container{
        margin-bottom: 75vw;
        
    } */
    
}
@media screen and (max-width: 500px) {
    .owners_txt, .f2{
        font-size: 1.5vw;
    }
    .owners h1{
        font-size: 3vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 5vw;
    }
    /* .about_container{
        margin-bottom: 150vw;
        
    } */
    
}
@media screen and (max-width: 400px) {
    .owners_txt, .f2{
        font-size: 2vw;
    }
    .owners h1{
        font-size: 5vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 6vw;
    }
    /* .about_container{
        margin-bottom: 85vw;
        
    } */
    
}
@media screen and (max-width: 300px) {
    .owners_txt, .f2{
        font-size: 2vw;
    }
    .owners h1{
        font-size: 5vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 6vw;
    }
    /* .about_container{
        margin-bottom: 120vw;
        
    } */
    
}
@media screen and (max-width: 200px) {
    .owners_txt, .f2{
        font-size: 3vw;
    }
    .owners h1{
        font-size: 6vw;
    }
    .company_name{
        margin-top:60px;
    }
    .name_company{
        font-size: 6vw;
    }
    /* .about_container{
        margin-bottom: 20%;
        
    } */
    
}

.owners_txt{
    margin: 1vh;
    max-height: 40vh;
}


.about_container{ 
    /* height: 100%; */
    min-height: 100vh;
}




.about_container::before{
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    right: 0px;
    /* bottom: 0; */
    background-image: url("../../sources/bg_about.jpeg");
    background-size: cover;
    /* filter: brightness(1.0005); */
    height: 100%;
    z-index: -3;
}
.bg_darker{
    background-color: #484848;
    height: 100%;
    color: white;
}
body{
 background-color: none;
 /* z-index: -2; */
 position: relative;
}
/* .text{
    display: flex;
}


.owners{
    width: 100%;
    float: right;
    clear: both;
}

.about_contain{
    height: 150vh;    
}

h1{
    font-size: 6vh;
}


.content{
    position: relative;
    top:20%;
    z-index: 2;
    height: 100%;
    font-size: 2.5vh;

}


.text_content{
    white-space: pre;
}

.flex-child {
    margin-bottom: 0px;
} 


.bottom_marg{
    margin-bottom: 0px;
}

.left_f{
    float: left;
    margin-left: 8%;
}

.contact_marg{
    margin-right: 10%;
} */