@media screen and (max-width: 1000000px) {

    .Popup{
        position: absolute;
        top: 10vh;
        /* height: 40vh; */
        width: 80vw;
        left: 10%;
    }

}
@media screen and (max-width: 600px) {

    .Popup{
        position: absolute;
        top: 20vh;
        height: 25vh;
        width: 95vw;
        left: 2.5%;
    }

}


.content{
    width: 100%;
    height: 100%;
    /* background-color: white; */
    border-radius: 10px;
    display: flex;
    gap: 0;
    /* border: 1px solid white; */
}

video{
    position: relative;
    right: 1.2%;
    border-radius: 10px;
    /* height: 25vh; */
}
.calcalist{
    width: 50%;
    /* height: 90%; */
    border-radius: 10px;
    position: relative;
    right: 1%;
}
.X{
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top:0;
    left: 0;
    z-index: 100;
    color: darkblue;
}

.hyper{
    /* width: 100%; */
    direction: rtl;
    justify-content: center;
    text-align: center;
    /* left: 40%; */
    font-size: 1.8rem;
}

a{
    text-decoration: none;
}