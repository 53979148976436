@media screen and (max-width: 1500000000px) {
    .headlines{
        width:85vw;
        font-size: 2vh;
    }
    .img_loc, .img_above{
        position:relative;
        top:-1%;
        width:70vw;
        height: 85vh;
    }
    .text_proj_page, .legend{
        font-size: 1vw;
    }
}
@media screen and (max-width: 1500px) {
    .headlines{
        width:86vw;
        font-size: 1.7vh;
    }
    .img_loc, .img_above{
        position:relative;
        top:-1%;
        width:80vw;
        height: 90vh;
    }
    .text_proj_page, .legend{
        font-size: 1.2vw;
    }
}
@media screen and (max-width: 1100px) {
    .headlines{
        width:85vw;
        font-size: 2vh;
    }
    .img_loc, .img_above{
        position:relative;
        top:-1%;
        width:80vw;
        height: 90vh;
    }
    .text_proj_page, .legend{
        font-size: 1.5vw;
    }
}
@media screen and (max-width: 900px) {
    .headlines{
        width: 83vw;
        font-size: 1.7vw;
    }
    .img_loc, .img_above{
        position:relative;
        top:-1%;
        width:75vw;
        height: 90vh;
    }
    
}
@media screen and (max-width: 750px) {
    .headlines{
        width: 80vw;
        font-size: 2vw;
    }
    .img_loc, .img_above{

        width:65vw;
        height: 55vh;
    }
}
@media screen and (max-width: 600px) {
    .img_loc, .img_above{
        width:55vw;
        height: 40vh;
    }
    .text_proj_page, .legend{
        font-size: 2vw;
    }
}
@media screen and (max-width: 400px) {
    .img_loc, .img_above{
        width:45vw;
        height: 30vh;
    }
    .text_proj_page, .legend{
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 300px) {
    .img_loc, .img_above{
        width:35vw;
        height: 20vh;
    }
    .text_proj_page, .legend{
        font-size: 2.5vw;
    }
}
.project_container{
    margin-top: 10vh;
    display: flex;
    font-size: 4vw;
    width: 100vw;
}
.img_loc, .img_above{
    position:relative;
    top:-1%;
}
.headlines{
    margin-top:3%;
    float: right;
    clear: both;
}

/* .classOpen{
    height: 100%;
} */

.page{
    float: right;
}
.pageLeft{
    float: left;
    margin-right: 4%;
}


.depFloat{
    float: right;
    clear: left;
    margin: 2%;
    height: 20%;
}
.spec_marg{
    margin-bottom: 1%;
}


.subjects{
    position: relative;
    right: 5%;
    height: 100%;
}



.home_icon{
    margin-right: 1%;
}