@media screen and (max-width: 1500000000px) {
    .display_list{
        font-size: 1vw;
    }
}
@media screen and (max-width: 1500px) {
    .display_list{
        font-size: 1.2vw;
    }
}
@media screen and (max-width: 1100px) {
    .display_list{
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 600px) {
    .display_list{
        font-size: 2vw;
    }
}
@media screen and (max-width: 400px) {
    .display_list{
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 300px) {
    .display_list{
        font-size: 2.5vw;
    }
}

.display_list{
    cursor:pointer;
}

.hr_style{
    border-top: 1px dotted;
}

.list_item{
    display:flex;
    position: relative;
    right: 5%;
}

.span_style{
    margin:0.8%;
}