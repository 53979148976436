@media screen and (max-width: 150000000000000px) {
    .container_contact{
        margin-top: 8%;
        margin-bottom: 10%;
        font-size: 1.5vw;
    }
    .col-6{
        font-size: 1.5vw;
    }

}
@media screen and (max-width: 1000px) {
    .container_contact{
        margin-top: 10%;
        font-size: 1.7vw;

    }
    .col-6{
        font-size: 1.8vw;
    }
}
@media screen and (max-width: 700px) {
    .container_contact{
        margin-top: 20%;
        font-size: 2vw;
    }
    .col-6{
        font-size: 2vw;
    }
}
@media screen and (max-width: 500px) {
    .container_contact{
        margin-top: 30%;
        font-size: 2.5vw;
    }
    .col-6{
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 300px) {
    .container_contact{
        margin-top: 40%;
        font-size: 3vw;
    }
    .col-6{
        font-size: 3vw;
    }
    
}

.contacts_head2{
    font-size: 4vw;
    white-space: pre-line;
}
h1{
    font-size: 3.5vw;
}
.container_contact{
    height:100vh;
    width: 100vw;

}
.label_value{
    float: right;
    clear: both;
    margin-bottom: 1%;
}

.butt_s{
    font-size: 1.4vw;
}

.col-6{
    width: 30%;
}

.feedback{
    width: 20%;
    margin-right: -3%;
}

.contacts_head{
    margin-bottom: 5%;
}

.form_container{
    position: relative;
    right: 40%;
}

.after_submit{
    position:relative;
    top:10%;
}

.company_logo{
    margin-top: 5%;
    /* position:relative;
    right:1%; */
    font-size: larger;
}


/* .thanks{
    position: relative;
    right: 4%;
} */
.thanks_text{
    /* position: relative;
    right: 8%; */
    font-size: larger;
}

.require{
    color: red;
}

body{
    background-color:#F5F5F5;
z-index: 0;
}