.contactUs{
    margin-top: 10rem;
}

@media screen and (max-width: 2000000px) {
    .Home{
        height: 150vh;
        margin-bottom:30%;
        width: 99vw;
    }
    .description_text{
        white-space: pre-line;
        font-size: 1.5rem;
    }
    .header_text, .home_txt{
        font-size: 4rem;
    }
}
@media screen and (max-width: 2000px) {
    .Home{
        height: 150vh;
        margin-bottom:130%;
        width: 99vw;
    }
    .description_text{
        white-space: pre-line;
        font-size: 1.5rem;
    }
    .header_text, .home_txt{
        font-size: 4rem;
    }
}
@media screen and (max-width: 1700px) {
    .Home{
        height: 150vh;
        margin-bottom:120%;
        width: 99vw;
    }
    .description_text{
        white-space: pre-line;
        font-size: 1.5rem;
    }
    .header_text, .home_txt{
        font-size: 4rem;
    }
}
@media screen and (max-width: 1500px) {
    .Home{
        height: 150vh;
        margin-bottom:180%;
        width: 99vw;
    }  
    .description_text{
        white-space: pre-line;
        font-size: 1.5rem;
    }
    .header_text, .home_txt{
        font-size: 4rem;
    }

}
@media screen and (max-width: 1000px) {
    .Home{
        height: 170vh;
        margin-bottom:170%;
        width: 99vw;
    }
    .description_text{
        white-space: pre-line;
        font-size: 1rem;
    }
    .header_text, .home_txt{
        font-size: 3.5rem;
    }
}
@media screen and (max-width: 500px) {
    .Home{
        height: 150vh;
        margin-bottom:350%;
        width: 99vw;
    }
    .description_text{
        white-space: pre-line;
        font-size: 1rem;
    }
    .header_text, .home_txt{
        font-size: 3.5rem;
    }
}

/* .Home{
    height: 350vh;
    margin-bottom:40%;
    width: 99vw;
} */

.classOpen{
    background-color:#F5F5F5;
    z-index: -20;
    position: relative;
}


/* .container{
    position: relative;
    height: 50%;
    width: 100%;
    right: -5%;
}  */

/* .description_text{
    white-space: pre-line;
    font-size: 1.2vw;
} */

.head_text{
    position: relative;
    z-index: 2;
    color: white;
    top: 30%;
}

/* .header_text, .home_txt{
    font-size: 6vw;
} */

.head{
    font-size: 2.7rem;
}
.nav_projects{
    background-color: goldenrod;
    color: white;
    font-size: 1rem;
}



.home_txt{
    color: gold;

}

.description{
    position:relative;
    top:2%;    
    height: 50%;
}

.description::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../sources/Home.jpeg");
    filter: brightness(50%);
    background-size: cover;
    z-index: 1;
}

.proj{
    position: relative;
    top: 5%;
}

.allProj{
    display: flex;
    flex-direction: column;
    align-items: center;
}
