@media screen and (max-width: 1500000000px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:40px;
        right: 75px;
        width:75vw;
        height: 85vh;
    }
}
@media screen and (max-width: 1500px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:25px;
        right: 25px;
        width:82vw;
        height: 90vh;
    }
}
@media screen and (max-width: 1100px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:15px;
        right: 0px;
        width:82vw;
        height: 60vh;
    }
}
@media screen and (max-width: 900px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:15px;
        width:80vw;
        height: 55vh;
    }
}
@media screen and (max-width: 750px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:10px;
        right: 25px;
        width:70vw;
        height: 45vh;
    }
}
@media screen and (max-width: 600px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:5px;
        right: 40px;
        width:60vw;
        height: 30vh;
    }
}
@media screen and (max-width: 400px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:5px;
        right: 30px;
        width:55vw;
        height: 25vh;
    }
}
@media screen and (max-width: 300px) {
    .contain_imaging, .swiper_img{
        position: relative;
        top:-5px;
        right: 25px;
        width:50vw;
        height: 15vh;
    }
}


.swiper{
    position:relative;
    top:-10%;
}